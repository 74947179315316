.btn{
    font-weight: bolder !important;
}

.btn-appbar{
    color: #fff !important;
}

.btn-disabled{
    color: rgba(68, 68, 68, 0.295) !important;
}