body{
    font-family: sans-serif;
    font-size: 1.5em;
    margin:0; 
    padding:0; 
}

.background-svg { 
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0 ;
    padding:0;
    margin:0;
}

.container{
    height: 50vh;
    position: relative;
}

.background{
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
}

.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:black;
    color: white;
    max-width: 450px;
    left: 50%;
    border-radius: 10px;
    padding: 20px 10px;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.login-wrapper form{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-wrapper input[type=text], .login-wrapper input[type=password]{
    margin-bottom: 15px;
    width: 380px;
    height: 20px;
    background-color: rgba(255, 255, 255, 0.863);
    padding: 13px 20px;
    height: 7px;
    font-weight: 500;
    border-radius: 4px;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.5px;
    outline: none;
    color: #c4c3ca;
    background-color: #1f2029;
    border: none;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
    box-shadow: 0 4px 8px 0 rgba(21,21,21,.2);
}

.login-wrapper input[type=text]:focus, .login-wrapper input[type=password]:focus {
    border: 1px solid #555;
}

.login-wrapper .label{
    padding: 0;
    margin: 0;
}


.login-wrapper .btn{  
    border-radius: 4px;
    width: 200px;
    height: 60px;
    margin-top: 15px;
    height: 44px;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    -webkit-transition : all 200ms linear;
    transition: all 200ms linear;
    padding: 0 30px;
    letter-spacing: 1px;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    text-align: center;
    border: none;
    background-color: #00B286;
    color: white;
    box-shadow: 0 8px 24px 0 rgba(255,235,167,.2);
  }
.login-wrapper .btn:active, .login-wrapper .btn:focus{  
    background-color: white;
    color: #00B286;
    box-shadow: 0 8px 24px 0 rgba(16,39,112,.2);
    cursor: pointer;
  }
.login-wrapper .btn:hover{  
    background-color: white;
    color: #00B286;
    box-shadow: 0 8px 24px 0 rgba(16,39,112,.2);
    cursor: pointer;
    width: 210px;
  }